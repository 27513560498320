<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.lab_nurse") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      >
        <div class="btn excel_btn">
          <button
              class="archive__btn"
              :class="{ active: isArchive }"
              @click="isArchive = !isArchive"
          >{{ $t(`message.archive`) }}
          </button>
        </div>
        <div class="btn excel_btn" v-loading.body="loadingData">
          <button
              @click="statisticFilter(item, index)"
              class="filter-wrap__btn"
              :class="{ active: index == activeFilterIndex }"
              v-for="(item, index) in items"
              :key="index"
          >
            {{ $t(`message.${item}`) }}
          </button>
        </div>
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Процедурный лист"
          name="Процедурный_лист.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'doctorCabinet'"
          @c-change="updateColumn"
        />
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      />
      <thead>
        <tr>
          <crm-th
            :column="columns.ids"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service_type_name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.ids.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.ids"
              :placeholder="columns.ids.title"
            ></el-input>
          </th>
          <th v-if="columns.patient.show">
            <el-input
              size="mini"
              v-model="filterForm.patient"
              :placeholder="columns.patient.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.service.show">
            <el-input
              size="mini"
              v-model="filterForm.service"
              :placeholder="columns.service.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.service_type_name.show">
            <el-input
              size="mini"
              v-model="filterForm.service_type_name"
              :placeholder="columns.service_type_name.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="patient in list"
          :key="patient.id + '-' + patient.service_id"
        >
          <td v-if="columns.ids.show">
            {{ patient.ids }}
          </td>
          <td v-if="columns.patient.show">
            {{ patient.patient_name }}
          </td>
          <td v-if="columns.service.show">{{ patient.service_name }}</td>
          <td v-if="columns.service_type_name.show">
            {{ patient.service_type_name }}
          </td>

          <td v-if="columns.settings.show" class="settings-td">
            <el-button
              round
              @click="show(patient)"
              size="mini"
              type="primary"
              icon="el-icon-view"
            ></el-button>

            <el-button
              round
              @click="print(patient)"
              size="mini"
              type="primary"
              icon="el-icon-printer"
            ></el-button>

            <el-button
              v-if="!isArchive"
              round
              @click="createModal(patient)"
              type="success"
              icon="el-icon-edit"
              >{{ $t("message.receive_") }}</el-button
            >
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      :visible.sync="drawerOrderShow"
      size="80%"
      :wrapperClosable="false"
      :drawer="drawerOrderShow"
    >
      <crm-show
        @c-close="closeDrawer"
        :order="selectedItem"
        :open="drawerOrderShow"
        drawer="drawerOrderShow"
      ></crm-show>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";
import CrmShow from "./components/crm-show";
export default {
  mixins: [list, drawer],
  name: "PatientServiceList",
  components: {
    CrmShow,
  },
  data() {
    return {
      drawerCreateCoculation: false,
      drawerUpdate: false,
      selectedModel: {},
      afterCreate: false,
      isArchive: false,
      selectedItem: null,
      drawerOrderShow: false,

      filterForm: {},
      reopenCreate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      activeItem: null,
      activeFilterIndex: 1,
      items: {
        1: "ambulatory",
        2: "stationary"
      }
    };
  },
  watch: {
    activeItem: {
      handler: function () {
        this.fetchData();
      }
    },
    isArchive: {
      handler: function () {
        this.fetchData();
      }
    },
  },
  computed: {
    ...mapGetters({
      list: "nurseCabinet/list",
      columns: "nurseCabinet/columns",
      pagination: "nurseCabinet/pagination",
      filter: "nurseCabinet/filter",
      sort: "nurseCabinet/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    await this.controlExcelData();
    this.defaulFilterItem = this.items[this.activeFilterIndex];
    this.statisticFilter(this.defaulFilterItem, this.activeFilterIndex);
  },
  methods: {
    ...mapActions({
      updateList: "nurseCabinet/nurseCabinet",
      updateFromNurse: "nurseCabinet/updateFromNurse",
      updateSort: "nurseCabinet/updateSort",
      updateFilter: "nurseCabinet/updateFilter",
      updateColumn: "nurseCabinet/updateColumn",
      updatePagination: "nurseCabinet/updatePagination",
      empty: "nurseCabinet/empty",
      refreshData: "nurseCabinet/refreshData",
      currentUser: "auth/getAuth",
    }),
    async fetchData() {
      const user = await this.currentUser()

      const query = {
        doctor: user.name,
        isArchive: this.isArchive,
        type: this.activeItem,
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData();
    },
    print(order) {
      this.waitingPrint = true;
      let vm = this;
      var jqxhr = $.get(
        process.env.VUE_APP_URL + `orders/print-nurse?id=${order.ids}`,
        function () {}
      )
        .done(function (res) {
          vm.waitingPrint = false;
          const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
          );
          WinPrint.document.write(res);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        })
        .fail(function () {
          alert(i18n.t("message.cannot_print"));
          vm.waitingPrint = false;
        });
    },
    async show(model) {
      this.selectedItem = model;
      this.drawerOrderShow = true;
    },
    async createModal(id) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.updateFromNurse(id)
            .then((res) => {
              this.$loadingCursor("default");
              this.refresh();
            })
            .catch((err) => {
              this.$loadingCursor("default");
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    edit(model) {
      this.selectedModel = model;
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      this.empty();
    },
    statisticFilter(item, index) {
      this.activeItem = item;
      this.activeFilterIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 16px;

  @media screen and (max-width: 1168px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  // filter-wrap__btn
  &__btn {
    outline: none;
    background: #fff;
    border: none;
    border-radius: 10px;
    padding: 6px 20px;
    color: #606266;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.15s ease-in-out;

    &.active {
      color: #fff;
      background: #409eff;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #fff;
      background: #66b1ff;
    }

    &:active {
      background: #3a8ee6;
    }
  }
}
.archive {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 16px;

  @media screen and (max-width: 1168px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  // filter-wrap__btn
  &__btn {
    outline: none;
    background: #fff;
    border: none;
    border-radius: 10px;
    padding: 6px 20px;
    color: #606266;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.15s ease-in-out;

    &.active {
      color: #fff;
      background: #409eff;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #fff;
      background: #66b1ff;
    }

    &:active {
      background: #3a8ee6;
    }
  }
}
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #303030;
      font-weight: 200 !important;
      font-size: 14px !important;
    }
  }
}
</style>



